import 'firebase/firestore';
import { useEffect } from 'react';
import { reactQueryClient } from 'shared/components/Providers/ThreadableQueryClientProvider';
import { queryKeys } from 'web/api/queryKeys';
import { useSession } from 'web/api/session';
import { firestore } from './init-firebase';

// https://cloud.google.com/firestore/docs/query-data/queries#web-version-8_2
export default function useLiveNotifications() {
  const { data: sessionUser } = useSession();

  const observeUserNotificationActivity = () => {
    const userNotificationSnapshot = firestore
      .collection('activity')
      .where('type', '==', 'notification')
      .where('entity_id', '==', sessionUser?.id)
      .where('entity_type', '==', 'User')
      .limit(1);

    // observes the activity collection for notification updates
    userNotificationSnapshot.onSnapshot(() => {
      // re-fetch new notifications
      reactQueryClient.invalidateQueries([queryKeys.notifications.index, { new: true }]);
    });
  };

  useEffect(() => {
    if (!sessionUser?.id) return;

    observeUserNotificationActivity();
  }, [sessionUser?.id]);
}
