import React from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { showToast } from 'web/utils/toast';

// client for react-query
export const reactQueryClient = new QueryClient({
  queryCache: new QueryCache({
    // since many RQ queries have auto retry, this is the only place to have a singe UI
    // effect for queries that fail.
    // See: https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose#a-bad-api
    // Requires the use of
    //   meta: {
    //     errorMessage: 'Failed to do shit',
    //   },
    onError: (error, query) => {
      if (query?.meta?.errorMessage) {
        showToast({
          message: `${query?.meta?.errorMessage}`,
          type: 'error',
        });
      }
    },
  }),
});

export default function ThreadableQueryClientProvider({ children }: { children: React.ReactNode }) {
  return <QueryClientProvider client={reactQueryClient}>{children}</QueryClientProvider>;
}
