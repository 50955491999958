// =================================================================
// === THIS IS AN AUTO-GENERATED FILE
// === DO NOT EDIT THIS FILE DIRECTLY
// === Edit its source file in javascript/erb/source and then
// === run `rails rails_constants:render` to regenerate this file
// =================================================================

// AUTOGENERATED DO NOT MODIFY!
export const ANALYTICS = {
  web: {
    ga4_tag_id: 'G-CZGJ970WTM',
  },
} as const;

// AUTOGENERATED DO NOT MODIFY!
const CIRCLE_VISIBILITY_TYPES = {
  OPEN: 'open',
  PUBLIC: 'public',
  EXPERT: 'expert',
  SOLO: 'solo',
  PRIVATE: 'private',
  ALL_ACCESS: 'all_access',
};

// AUTOGENERATED DO NOT MODIFY!
export default {
  CIRCLE_VISIBILITY_TYPES,
  assets: {},
  cypress: {
    BRANCH_API_KEY: '',
    SENTRY_DSN: null,
    firebase: {
      apiKey: 'AIzaSyBP9YzaT76KxZtAgrh_hP-4Meg6P-Atmzc',
      authDomain: 'threadable-reader-db.firebaseapp.com',
      projectId: 'threadable-reader-db',
      storageBucket: 'threadable-reader-db.appspot.com',
      messagingSenderId: 966071927481,
      appId: '1:966071927481:web:0180d286d76a03943f9168',
      measurementId: 'G-JP4V94P4MX',
    },
  },
  development: {
    BRANCH_API_KEY: 'key_test_iAoAHRANFDHQka2p7AXoDibjECpoF08k',
    SENTRY_DSN:
      'https://1854a3bf5358e7b7d3a85f476fbbd98e@o4505512652505088.ingest.sentry.io/4506066736381952',
    firebase: {
      apiKey: 'AIzaSyAJdTBOBfkxfm7q7hkRJC0GVfsAlpJW3I4',
      authDomain: 'threadable-staging.firebaseapp.com',
      projectId: 'threadable-staging',
      storageBucket: 'threadable-staging.appspot.com',
      messagingSenderId: 43607622447,
      appId: '1:43607622447:web:66ce00f37698e833f5d7da',
      measurementId: 'G-P961RE3YVH',
    },
  },
  production: {
    BRANCH_API_KEY: 'key_live_nwfFGTDUDwNHgm3jYF9lBpcgBDolB49x',
    SENTRY_DSN:
      'https://1854a3bf5358e7b7d3a85f476fbbd98e@o4505512652505088.ingest.sentry.io/4506066736381952',
    firebase: {
      apiKey: 'AIzaSyBP9YzaT76KxZtAgrh_hP-4Meg6P-Atmzc',
      authDomain: 'threadable-reader-db.firebaseapp.com',
      projectId: 'threadable-reader-db',
      storageBucket: 'threadable-reader-db.appspot.com',
      messagingSenderId: 966071927481,
      appId: '1:966071927481:web:0180d286d76a03943f9168',
      measurementId: 'G-JP4V94P4MX',
    },
  },
  staging: {
    BRANCH_API_KEY: 'key_test_iAoAHRANFDHQka2p7AXoDibjECpoF08k',
    SENTRY_DSN:
      'https://1854a3bf5358e7b7d3a85f476fbbd98e@o4505512652505088.ingest.sentry.io/4506066736381952',
    firebase: {
      apiKey: 'AIzaSyAJdTBOBfkxfm7q7hkRJC0GVfsAlpJW3I4',
      authDomain: 'threadable-staging.firebaseapp.com',
      projectId: 'threadable-staging',
      storageBucket: 'threadable-staging.appspot.com',
      messagingSenderId: 43607622447,
      appId: '1:43607622447:web:66ce00f37698e833f5d7da',
      measurementId: 'G-P961RE3YVH',
    },
  },
  test: {
    BRANCH_API_KEY: '',
    SENTRY_DSN: null,
    firebase: {
      apiKey: 'testingtestingtesting123',
      authDomain: 'testing.test.com',
      projectId: 'testing-testing',
      storageBucket: 'testing.test.com',
      messagingSenderId: 12345,
      appId: '1:987654321:web:0123456',
      measurementId: 'G-ABC123',
    },
  },
};

// AUTOGENERATED DO NOT MODIFY!
export const routingConstants = {
  circles: {
    base: 'circles',
    read: 'read',
  },
  documents: {
    base: 'documents',
    tabs: {
      reviews: '',
      threads: 'threads',
    },
  },
  public: {
    terms: 'terms',
  },
  settings: {
    accountSettings: {
      base: 'account-settings',
      manage: 'manage-account',
      delete: 'delete-account',
    },
  },
  userProfile: {
    base: 'user-profile',
    circles: 'circles',
    previews: 'previews',
  },
  search: {
    base: 'search',
    results: {
      base: 'results',
      books: 'books',
      circles: 'circles',
      users: 'users',
    },
  },
} as const;

// AUTOGENERATED DO NOT MODIFY!
export const DOCUMENT_REQUEST = {
  statuses: {
    pending: 'pending',
    fulfilled: 'fulfilled',
    rejected: 'rejected',
  },
} as const;

// AUTOGENERATED DO NOT MODIFY!
export const BOOKMARK_STATUSES = ['saved', 'reading', 'deleted'] as const;
