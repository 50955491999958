import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { api } from '.';
import { Genre } from 'web/store/genres/types';

export const useGenres = () => {
  return useQuery([queryKeys.genres.index], async () => {
    const response = await api.get<Genre[]>('/genres');
    return response.data;
  });
};

export const useGenresForOnboarding = () => {
  return useQuery([queryKeys.genres.onboarding], async () => {
    const response = await api.get<Genre[]>('/genres', { params: { onboarding: true } });
    return response.data;
  });
};
